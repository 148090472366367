import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Link from "./Link"

import PageHeader from "./PageHeader"

export default function SinglePortfolioPage({ data }) {
  const { content, title, portfolioItem } = data.wpPortfolioItem
  const { imagesPortfolioItem, descriptionPortfolioItem } = portfolioItem
  return (
    <div className="wp-page h-full">
      <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-16 max-w-6xl mx-auto">
        <PageHeader text={title} />
        <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
          {/* Primary column */}
          <div className="lg:col-span-2 overflow-hidden space-y-8">
            <div className="grid gap-8">
              {imagesPortfolioItem.map((item, i) => {
                return <PortfolioImage key={item.id} item={item} />
              })}
            </div>
            <div>
              <div className="text-2xl lg:text-4xl  no-underline">
                <Link to="/work" className=" no-underline">
                  &larr;
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 flex-1 overflow-hidden space-y-4 mt-4 lg:mt-0">
            <div>
              <div
                className="wp-content"
                dangerouslySetInnerHTML={{ __html: descriptionPortfolioItem }}
              />
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

function PortfolioImage({ item }) {
  const img = item.imagePortfolioItem?.localFile.childImageSharp.gatsbyImageData
  return <div>{img && <GatsbyImage image={getImage(img)} />}</div>
}
