import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SinglePortfolioPage from "../components/common/SinglePortfolioPage"

export default function PageTemplate({ data }) {
  return (
    <div className="">
      <Seo title={data.wpPortfolioItem.title} />

      <Layout>
        <SinglePortfolioPage data={data} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPortfolioItem(id: { eq: $id }) {
      title
      content
      portfolioItem {
        imagesPortfolioItem {
          imagePortfolioItem {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        descriptionPortfolioItem
      }
    }
  }
`
